import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static values = {
		framePath: { type: String, default: '/reports/fields' }
	}
	static targets = ['frame']

	getFields(e) {
		this.frameTarget.src = this.framePathValue + '?view=' + e.target.value
	}
}
