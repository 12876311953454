import { Controller } from '@hotwired/stimulus'
import Hashids from 'hashids'
const hashids = new Hashids('', 0, 'abcdefghjkmnpqrstuvwxyz23456789')

export default class extends Controller {
	static values = {
		suggestions: { type: String, default: '/products/customs_description_suggestions' }
	}
	static targets = [ 'sku', 'trackStock', 'stockInfo', 'commodity', 'loader', 'help', 'helpError', 'helpSuccess', 'description', 'lengthHelp', 'lengthWarning', 'ignore', 'suggestions' ]

	connect() {
		this.toggleStockInfo()
		this.checkDescription()
	}

	generate() {
		this.skuTarget.value = hashids.encode(Date.now()).toUpperCase()
	}

	toggleStockInfo() {
		if (this.trackStockTarget.checked && !this.ignoreTarget.checked) {
			this.stockInfoTargets.forEach(e => e.querySelector('label').classList.remove('color-fg-subtle'))
			this.stockInfoTargets.forEach(e => e.querySelector('input').disabled = false)
		} else {
			this.stockInfoTargets.forEach(e => e.querySelector('label').classList.add('color-fg-subtle'))
			this.stockInfoTargets.forEach(e => e.querySelector('input').disabled = true)
		}
	}

	checkCommodity() {
		var enteredCode = this.commodityTarget.querySelector('input').value
		this.commodityTarget.classList.remove('successed', 'errored')
		this.helpTarget.classList.remove('v-hidden')
		this.helpErrorTarget.classList.add('d-none')
		this.helpSuccessTarget.classList.add('d-none')
		if (enteredCode.length == 10) {
		this.loaderTarget.classList.remove('d-none')
			fetch('/products/commodity_validator/'+enteredCode).then(response => {
				this.loaderTarget.classList.add('d-none')
				this.helpTarget.classList.add('v-hidden')
				if (response.status == 200) {
					this.commodityTarget.classList.add('successed')
					this.helpSuccessTarget.classList.remove('d-none')
				} else {
					this.commodityTarget.classList.add('errored')
					this.helpErrorTarget.classList.remove('d-none')
				}
			})
		}
	}

	checkDescription() {
		if (this.descriptionTarget.querySelector('input').value.length > 24) {
			this.descriptionTarget.classList.add('warn')
			this.lengthHelpTarget.classList.add('v-hidden')
			this.lengthWarningTarget.classList.remove('d-none')
		} else {
			this.descriptionTarget.classList.remove('warn')
			this.lengthHelpTarget.classList.remove('v-hidden')
			this.lengthWarningTarget.classList.add('d-none')
		}
		this.suggestionsTarget.src = this.suggestionsValue+'?start='+this.descriptionTarget.querySelector('input').value
	}

	acceptSuggestion(e) {
		e.preventDefault()
		this.descriptionTarget.querySelector('input').value = e.target.textContent
	}
}
