import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
	static targets = ['dialog', 'frame']

	loaded() {
		const iframe = this.frameTarget.querySelector('iframe')
		if (iframe != null) {
			iframe.onload = (e) => {
				e.target.style.height = e.target.contentWindow.document.body.scrollHeight + 'px'
			}
		}
		this.dialogTarget.showModal()
	}
}
